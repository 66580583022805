export const timeframes = [
  {
    time: 1000 * 60 * 60,
    id: '1H',
    action: '1H',
    label: '1h',
    frequency: '1min',
    seconds: 60,
    frequency_time: 1 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 6,
    id: '6H',
    action: '6H',
    label: '6h',
    frequency: '5min',
    seconds: 300,
    frequency_time: 5 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 24,
    id: '1D',
    action: '1D',
    label: '1d',
    frequency: '1hour',
    seconds: 3600,
    frequency_time: 60 * 60 * 1000
  },
  {
    time: 1000 * 60 * 60 * 24 * 7,
    id: '7D',
    action: '7D',
    label: '7d',
    frequency: '6hour',
    seconds: 3600 * 6,
    frequency_time: 60 * 60 * 1000 * 6
  },
  {
    time: 1000 * 60 * 60 * 24 * 30,
    id: '30D',
    action: '30D',
    label: '30d',
    frequency: '1day',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 90,
    id: '90D',
    action: '90D',
    label: '3M',
    frequency: '1day',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 180,
    id: '6M',
    action: '6M',
    label: '6M',
    frequency: '1day',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  },
  {
    time: 1000 * 60 * 60 * 24 * 365,
    id: '1Y',
    action: '1Y',
    label: '1Y',
    frequency: '1day',
    seconds: 3600 * 24,
    frequency_time: 1000 * 60 * 60 * 24
  }
];
